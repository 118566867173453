import React, { useState, useCallback } from "react";
// mui
import { TextField } from "@mui/material";
import { usePageParams } from "../../hooks/usePageParams";

const SearchBox = ({ searchValue, setSearchValue }) => {
    const { page, setPage } = usePageParams();
    const [value, setValue] = useState(searchValue);
    const handleChange = (e) => {
        setValue(e.target.value);
        setTimeout(() => {
            setValueOfSearchBox(e.target.value.trim());
        }, 1000);
    };
    const setValueOfSearchBox = useCallback(
        (value) => {
            setSearchValue(value);
            setPage("1");
        },
        [value]
    );
    return (
        <TextField
            id="sort-box"
            name="search_box"
            label="بحث"
            value={value}
            onChange={handleChange}
            sx={{
                minWidth: "230px",
                flex: "1",
            }}
        />
    );
};

export default SearchBox;
