import React, { useState, useEffect } from "react";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// mui
import { Autocomplete, TextField } from "@mui/material";
// icons
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const CitySelect = ({ setValues, values, resetCity = false, setResetCity }) => {
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [defaultCity, setDefaultCity] = useState(null);
    const [value, setValue] = useState(null);
    // --------------------------------------- handle_autocomplete_changing ------------------------
    const handleAutocompleteChange = (e, newValue) => {
        setValues((prev) => ({
            ...prev,
            city: { id: newValue ? newValue.id : "" },
        }));
        setValue(newValue ?? null);
    };
    useEffect(() => {
        EnhancedAxios(null, null)
            .get(`/cities?paginate=500`)
            .then((response) => {
                setCities(response.data.data);
                setLoading(false);
                setDefaultCity(
                    response.data.data.find(
                        (city) => city.id === values.city_id
                    )
                );
                setValue(
                    response.data.data.find(
                        (city) => city.id === values.city_id
                    )
                );
            });
    }, []);
    useEffect(() => {
        if (resetCity) {
            setValue(null);
            setResetCity(false);
        }
    }, [resetCity]);
    // --------------------------------------- page_components ------------------------
    return (
        <Autocomplete
            sx={{ width: "100%" }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            options={cities}
            loading={loading}
            onChange={handleAutocompleteChange}
            defaultValue={defaultCity ?? null}
            value={value ?? null}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="المدينة"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <AiOutlineLoading3Quarters /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default CitySelect;
