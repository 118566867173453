import { useSearchParams } from "react-router-dom"

export const usePageParams = ()=>{
    const [searchParams, setSearchParams] = useSearchParams();
    let page =1 ;
    const pageParam = searchParams.get('page');
    if(pageParam){
        const n = parseInt(pageParam,10);
        if(!isNaN(n) && n>1) page = n;
    }
    const setPage = (page) =>{
        searchParams.set('page', page.toString());
        setSearchParams(searchParams);
    }
    return {page, setPage}
}