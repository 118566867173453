import React, { useState, useEffect } from "react";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// mui
import { Autocomplete, TextField } from "@mui/material";
// icons
import { AiOutlineLoading3Quarters } from "react-icons/ai";
// hooks
import { usePageParams } from "../../hooks/usePageParams";
const CategoriesFilterBox = ({
    categoryParentId = process.env.React_APP_PARENT_ID_SOS_CATEGORIES,
    setFilterValues,
}) => {
    const { page, setPage } = usePageParams();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(null);
    // --------------------------------------- handle_autocomplete_changing ------------------------
    const handleAutocompleteChange = (e, newValue) => {
        setFilterValues((prev) => ({
            ...prev,
            category: newValue ? `category_id:${newValue.id}` : "",
        }));
        setValue(newValue ?? null);
        setPage("1");
    };
    // --------------------------------------- get_all_cities ------------------------
    useEffect(() => {
        EnhancedAxios(null, null)
            .get(
                `/categories?filters=parent_id:${categoryParentId}&page=1&paginate=500`
            )
            .then((response) => {
                setCategories(response.data.data);
                setLoading(false);
            });
    }, []);
    return (
        <Autocomplete
            sx={{ flex: "1", minWidth: "230px" }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            options={categories}
            loading={loading}
            onChange={handleAutocompleteChange}
            value={value ?? null}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="الفئة"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <AiOutlineLoading3Quarters /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default CategoriesFilterBox;
