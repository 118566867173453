import * as yup from "yup";
const phoneRegex = new RegExp(/^[+]?[0-9]{10,14}$/);
const maxImgSizeMB = 2;
const maxImgSizeBytes = maxImgSizeMB * 2 ** 20;
export const editDriverSchema = yup.object().shape({
    img: yup
        .mixed()
        .nullable()
        .test(
            "fileSize",
            `حجم الصورة كبير جدا يجب الا يتعدى حجد الصورة ${maxImgSizeMB}MB`,
            (value) => imageEditSizeValidate(value)
        )
        .test("fileType", "امتداد الصورة يجب ان يكون jpg , png", (value) =>
            imageEditTypeValidate(value)
        ),
    name: yup
        .string()
        .min(4, "يجب ان يكون اسم المستخدم اكثر من 4 حروف")
        .required("من فضلك ادخل اسم المستخدم "),
    phone: yup
        .number()
        .min(12, "رقم الهاتف قصير يجب ان يكون رقم الهاتف مكوم من 12 رقم")
        .required("ادخل رقم الهاتف خاصة السائق"),
    email: yup.string().email("ادخل البريد الالكترونى الصحيح للسائق"),
    has_second_job: yup.boolean(),
    nationality: yup.string().min(2, "اسم الجنسية قصير ").nullable(),
    car_type: yup.string().nullable(),
    car_year: yup.number().nullable(),
    car_model: yup.string().nullable(),
    is_disabled: yup.boolean(),
    password: yup.string().min(8, " الرقم السري قصير جدا"),
    password_confirmation: yup
        .string()
        .oneOf([yup.ref("password")], "تأكد من تطابق الرقم السري"),
});

const imageEditSizeValidate = (value) => {
    if (value != null && typeof value != "string") {
        return value.size <= maxImgSizeBytes;
    }
    return true;
};

const imageEditTypeValidate = (value) => {
    if (value != null && typeof value != "string") {
        return ["image/jpg", "image/png", "image/jpeg"].includes(value.type);
    }
    return true;
};
