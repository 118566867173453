import React, { useEffect } from "react";
// react redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// mui
import { Button } from "@mui/material";
import { useState } from "react";
import { errorAlert, successAlert } from "../../utils/alerts";
import { warningAlert } from "../../utils/alerts";

const ApproveDriverJoinRequest = ({ id }) => {
    const userToken = useSelector((state) => state.userToken);
    const [disableButton, setDisableButton] = useState(false);

    const approve = async () => {
        // call to get the req info
        // if req success check on is_rejected value
        EnhancedAxios(null, userToken)
            .get(`/admin/driver-forms/${id}`)
            .then((res) => {
                if (res.data.is_rejected) {
                    warningAlert(
                        "لا يمكنك قبول السائق في حالة ان الطلب خاصته مرفرض"
                    );
                } else {
                    EnhancedAxios(null, userToken)
                        .post(`/admin/driver-forms/${id}`)
                        .then((res) => {
                            successAlert("تم قبول الطلب");
                            setDisableButton(true);
                        })
                        .catch((error) => {
                            errorAlert(error);
                        });
                }
            })
            .catch((error) =>
                warningAlert(
                    "حدث خطآ عند الحصول علي معلومات الطلب حاول في وقت لاحق"
                )
            );
    };
    return (
        <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={disableButton}
            onClick={approve}
        >
            قبول
        </Button>
    );
};

export default ApproveDriverJoinRequest;
