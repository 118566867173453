import { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// hooks
import { usePageParams } from "../../../hooks/usePageParams";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import { Stack, Box, Typography } from "@mui/material";
//components
import {
    PageTitle,
    DriverSelect,
    SortBox,
    RingsLoader,
    SearchBox,
    DownloadCSV,
} from "../../../components";
import { TableGrid } from "../../../sections";
// alerts
import { errorAlert } from "../../../utils/alerts";
// cols
import { ordersCols } from "../../../utils/gridCols/ordersCols";

const Orders = () => {
    const userToken = useSelector((state) => state.userToken);
    const { page, setPage } = usePageParams();
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [searchValue, setSearchValue] = useState("");
    const [totalItems, setTotalItems] = useState(0);
    const [sortValue, setSortValue] = useState("-created_at");
    const [filterValues, setFilterValues] = useState({
        city_id: "",
        status: "is_disabled:eq:false",
        intialDate: "",
        finishDate: "",
        orderStatus: "",
        orderType: "",
        driver_id: "",
    });
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(
                `/admin/trips?page=${page}&paginate=${itemsPerPage}&sorts=${sortValue}&filters=${filterValues.driver_id}&q=${searchValue}`
            )
            .then((res) => {
                setOrders(res.data.data);
                setTotalItems(res.data.meta.total);
                setLoading(false);
            })
            .catch((error) => errorAlert(error));
    }, [page, itemsPerPage, searchValue, sortValue, filterValues]);
    return (
        <Stack gap="20px">
            <PageTitle title="إدارة الطلبات" />
            <DownloadCSV
                title="تقرير الرحلات"
                endpoint="/admin/trips/report"
                downloadedFilename="report=trips"
            />
            <Stack gap="20px">
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap="20px"
                    flexWrap="wrap"
                >
                    <SearchBox
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                    <SortBox
                        sortValue={sortValue}
                        setSortValue={setSortValue}
                    />
                    <DriverSelect setFilterValues={setFilterValues} />
                </Box>
            </Stack>
            <Stack>
                {loading ? (
                    <RingsLoader />
                ) : orders.length === 0 ? (
                    <Typography variant="body1" textAlign="center">
                        لا يوجد عناصر لعرضها.
                    </Typography>
                ) : (
                    <TableGrid
                        rows={orders}
                        cols={ordersCols}
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        currentPage={page}
                        setCurrentPage={setPage}
                    />
                )}
            </Stack>
        </Stack>
    );
};

export default Orders;
