import { FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material";
// hooks
import { usePageParams } from "../../hooks/usePageParams";
// filters option
const statusFiltersOptions = [
    {
        label: "الكل",
        value: "is_disabled:eq:true|false",
    },
    {
        label: "غير معطل",
        value: "is_disabled:eq:false",
    },
    {
        label: "معطل",
        value: "is_disabled:eq:true",
    },
];

const DisableStatusFilterBox = ({ filterValues, setFilterValues }) => {
    const { page, setPage } = usePageParams();

    const handleChange = (e) => {
        setFilterValues({
            ...filterValues,
            status: e.target.value,
        });
        setPage("1");
    };
    return (
        <Box sx={{ minWidth: "230px", flex: "1" }}>
            <FormControl fullWidth>
                <InputLabel>الحالة</InputLabel>
                <Select
                    name="status_filter"
                    value={filterValues.status}
                    label="الحالة"
                    onChange={handleChange}
                    defaultValue={filterValues.status}
                >
                    {statusFiltersOptions.map((option) => {
                        return (
                            <MenuItem value={option.value} key={option.value}>
                                {option.label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
};

export default DisableStatusFilterBox;
