import React, { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// form
import { useFormik } from "formik";
// mui
import { Stack, Box, TextField, Typography } from "@mui/material";
// components
import { RingsLoader, SubmitButton } from "../../components";
import SocialLinkForm from "./SocialLinkForm";
// alerts
import { errorAlert } from "../../utils/alerts";
import * as yup from "yup";

// ------------------------------------ page_function_components ----------------------------
const SystemOptions = () => {
    const userToken = useSelector((state) => state.userToken);
    const [isLoading, setIsLoading] = useState(true);
    // get settings data
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/admin/settings?sorts=updated_at&page=1&paginate=9999`)
            .then((response) => {
                let allOptions = response.data.data;
                allOptions.forEach((el) => {
                    if (optionsWeNeedKeys.includes(el.key)) {
                        let currentOptionIndex = optionsWeNeedData.findIndex(
                            (obj) => obj.key === el.key
                        );

                        console.log("field number");
                        optionsWeNeedData[currentOptionIndex].id = el.id;
                        optionsWeNeedData[currentOptionIndex].value =
                            // parseFloat(el.value.replace(/^0+/, ""));
                            parseFloat(el.value.replace(/\.?0+$/, ""));
                    }
                });
                setIsLoading(false);
            })
            .catch((error) => errorAlert(error));
    }, []);

    return (
        <Stack gap="20px">
            <Typography variant="h5">خيارات النظام</Typography>
            {isLoading ? (
                <RingsLoader />
            ) : (
                optionsWeNeedData.map((opt) => (
                    <SocialLinkForm
                        key={opt.id}
                        id={opt.id}
                        itemKey={opt.key}
                        value={opt.value}
                        title={opt.title}
                        fieldType={opt.fieldType}
                        schema={opt.schema}
                    />
                ))
            )}
        </Stack>
    );
};

export default SystemOptions;

// utils
const optionsWeNeedData = [
    {
        key: "cost_max_free_customer_cancel_minutes",
        title: "مدة الالغاء المجانى للطلب من  العميل بالدقيقة",
        id: 0,
        value: "",
        fieldType: "number",
        schema: yup.object().shape({
            value: yup
                .number()
                .typeError("مدة الالغاء المجانى يجب ان تكون رقم")
                .required("ادخل القيمة المراد التعديل اليها")
                .min(0, "القيمة يجب ان تكون اكبر من الصفر"),
        }),
    },
    {
        key: "cost_extra_meters_rate",
        title: "تكلفة المسافة الاضافية لكل متر",
        id: 0,
        value: "",
        fieldType: "number",
        schema: yup.object().shape({
            value: yup
                .number()
                .typeError("تكلفة المسافة الاضافية يجب ان تكون رقم")
                .required("ادخل القيمة المراد التعديل اليها")
                .min(0, "القيمة يجب ان تكون اكبر من الصفر"),
        }),
    },
    {
        key: "cost_base_price",
        title: "التكلفة الاساسية للطلب ( د.ل )",
        id: 0,
        value: "",
        fieldType: "number",
        schema: yup.object().shape({
            value: yup
                .number()
                .typeError("التكلفة الاساسبة للطلب يجب ان تكون رقم")
                .required("ادخل القيمة المراد التعديل اليها")
                .min(0, "القيمة يجب ان تكون اكبر من الصفر"),
        }),
    },
    {
        key: "cost_max_driver_wait_minutes",
        title: "مدة انتظار السائق للعميل بالدقيقة",
        id: 0,
        value: "",
        fieldType: "number",
        schema: yup.object().shape({
            value: yup
                .number()
                .typeError("  مدة الانتظار يجب ان تكون رقم")
                .required("ادخل القيمة المراد التعديل اليها")
                .min(0, "القيمة يجب ان تكون اكبر من الصفر"),
        }),
    },
    {
        key: "cost_hourly_rate",
        title: "تكلفة الساعة فى الرحلة ( د.ل )",
        id: 0,
        value: "",
        fieldType: "number",
        schema: yup.object().shape({
            value: yup
                .number()
                .typeError("  تكلفة الساعة فى الرحلة يجب ان تكون رقم")
                .required("ادخل القيمة المراد التعديل اليها")
                .min(0, "القيمة يجب ان تكون اكبر من الصفر"),
        }),
    },
    {
        key: "cost_max_free_extra_meters",
        title: "المسافة المجانية من العلامة الدالة بالمتر",
        id: 0,
        value: "",
        fieldType: "number",
        schema: yup.object().shape({
            value: yup
                .number()
                .typeError("  المسافة المجانية يجب ان تكون رقم")
                .required("ادخل القيمة المراد التعديل اليها")
                .min(0, "القيمة يجب ان تكون اكبر من الصفر"),
        }),
    },
    {
        key: "cost_profit_percent",
        title: "نسبة الربح من التوصيل (0-1)",
        id: 0,
        value: "",
        fieldType: "number",
        schema: yup.object().shape({
            value: yup
                .number()
                .typeError("  نسبة الربح يجب ان تكون رقم")
                .required("ادخل القيمة المراد التعديل اليها")
                .min(0, "القيمة يجب ان تكون اكبر من الصفر"),
        }),
    },
    {
        key: "trip_luxury_percent",
        title: "نسبة الزيادة فى سعر الرحلة عند طلب سيارة فارهة ( 0-1 )",
        id: 0,
        value: "",
        fieldType: "number",
        schema: yup.object().shape({
            value: yup
                .number()
                .typeError("  نسبة الربح يجب ان تكون رقم")
                .required("ادخل القيمة المراد التعديل اليها")
                .min(0, "القيمة يجب ان تكون اكبر من الصفر"),
        }),
    },
    {
        key: "trip_minimum_arrive_distance",
        title: "مسافة اقتراب المندوب من نقطة استقبال المندوب للراكب ( متر )",
        id: 0,
        value: "",
        fieldType: "number",
        schema: yup.object().shape({
            value: yup
                .number()
                .typeError("المسافة يجب ان تكون رقم صحيح")
                .required("ادخل القيمة المراد التعديل اليها")
                .min(0, "القيمة يجب ان تكون اكبر من الصفر"),
        }),
    },
    {
        key: "trip_minimum_dropoff_distance",
        title: "مسافة اقتراب المندوب من نقطة ايصال الراكب للزبون ( متر )",
        id: 0,
        value: "",
        fieldType: "number",
        schema: yup.object().shape({
            value: yup
                .number()
                .typeError("المسافة يجب ان تكون رقم صحيح")
                .required("ادخل القيمة المراد التعديل اليها")
                .min(0, "القيمة يجب ان تكون اكبر من الصفر"),
        }),
    },
    {
        key: "cost_meter_rate",
        title: " معدل سعر المتر للمسافات القصيرة (0-1)",
        id: 0,
        value: "",
        fieldType: "number",
        schema: yup.object().shape({
            value: yup
                .number()
                .typeError("السعر يجب ان يكون رقم صحيح")
                .required("ادخل القيمة المراد التعديل اليها")
                .min(0, "القيمة يجب ان تكون اكبر من الصفر"),
        }),
    },
    {
        key: "nearby_alert_distance_meters",
        title: "النطاق الدائري من نقطة الزبون  لابلاغ السائقين على وجود طلب جديد (بالمتر)",
        id: 0,
        value: "",
        fieldType: "number",
        schema: yup.object().shape({
            value: yup
                .number()
                .typeError("المسافة يجب ان تكون رقم صحيح")
                .required("ادخل القيمة المراد التعديل اليها")
                .min(0, "القيمة يجب ان تكون اكبر من الصفر"),
        }),
    },
];
const optionsWeNeedKeys = [
    "cost_max_free_customer_cancel_minutes",
    "cost_extra_meters_rate",
    "cost_base_price",
    "cost_max_driver_wait_minutes",
    "cost_hourly_rate",
    "cost_max_free_extra_meters",
    "cost_profit_percent",
    "trip_luxury_percent",
    "trip_minimum_arrive_distance",
    "trip_minimum_dropoff_distance",
    "cost_meter_rate",
    "nearby_alert_distance_meters",
];
