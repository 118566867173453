import React from "react";
// redux
import { useSelector } from "react-redux";
// mui
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    Stack,
} from "@mui/material";
// formik
import { useFormik } from "formik";
// utils
import { successAlert, errorAlert } from "../../utils/alerts";
import EnhancedAxios from "../../api/EnhancedAxios";
import { SubmitButton } from "../../components";

const AddPrePaidCard = ({ open, setOpen, setRerender }) => {
    const userToken = useSelector((state) => state.userToken);
    function isCSVFile(file) {
        // Check MIME type and file extension
        const isMimeTypeCSV = file.type === "text/csv";
        const isExtensionCSV = file.name.toLowerCase().endsWith(".csv");

        return isMimeTypeCSV || isExtensionCSV;
    }
    const onSubmit = (values) => {
        if (!values.csv) {
            errorAlert({ message: "يجب ارفاق ملف يحتوي علي بيانات الكروت" });
            setSubmitting(false);
            return;
        }
        if (!isCSVFile(values.csv)) {
            errorAlert({ message: "يجب ان يكون الملف من نوع CSV" });
            setSubmitting(false);
            return;
        }
        EnhancedAxios("multipart/form-data", userToken)
            .post(`/admin/prepaid-cards`, values)
            .then((res) => {
                successAlert(`تم اضافة كروت جديدة بنجاح`);
                setSubmitting(false);
                resetForm();
                setOpen(false);
                setRerender(Math.random());
            })
            .catch((error) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    const {
        values,
        handleChange,
        handleSubmit,
        handleBlur,
        setValues,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            csv: null,
        },
        onSubmit,
    });

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>اضافة كروت مسبقة الدفع</DialogTitle>
            <DialogContent>
                <Button
                    component={"a"}
                    href={"/prepaid-cards-sample.csv"}
                    download
                    variant="outlined"
                    sx={{ mb: 2 }}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                >
                    تحميل ملف CSV المبدئي
                </Button>
                <Stack component="form" onSubmit={handleSubmit} gap="20px">
                    <TextField
                        // value={values.csv}
                        name="csv"
                        onChange={(e) =>
                            setValues(() => ({ csv: e.target.files[0] }))
                        }
                        type="file"
                    />
                    <SubmitButton text={"اضافة"} disabled={isSubmitting} />
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default AddPrePaidCard;
