import React, { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// router
import { useParams } from "react-router";
import { Link } from "react-router-dom";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import { Stack, Divider, Box, Button } from "@mui/material";
// components
import { PageTitle, RingsLoader } from "../../../components";
import {
    DriverChangeCash,
    EditDriverForm,
    DriverInfo,
} from "../../../sections";
import AddTripForDriver from "../../../sections/popups/AddTripForDriver";
// driver info

const EditDriver = () => {
    const { driverId } = useParams();
    const userToken = useSelector((state) => state.userToken);
    const [driver, setDriver] = useState({});
    const [loading, setLoading] = useState(true);
    const [openChargeCashForm, setOpenChargeCashForm] = useState(false);
    const [openAddTripForDriver, setOpenAddTripForDriver] = useState(false);
    const [afterEditRes, setAfterEditRes] = useState(null);
    const [rerender, setRerender] = useState(Math.random());
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(`/admin/users/${driverId}`)
            .then((res) => {
                setDriver(res.data);
                setLoading(false);
            });
    }, [rerender]);
    // ---------- page_ui ----------

    if (loading) return <RingsLoader />;
    return (
        <Stack width="100%" alignItems="center">
            <Stack width="100%" maxWidth="800px">
                <PageTitle title="تفاصيل السائق" />
                {/* driver form edit information  */}
                <EditDriverForm
                    img={driver.img}
                    name={driver.name}
                    phone={driver.phone}
                    email={driver.email}
                    is_disabled={driver.is_disabled}
                    has_second_job={driver.has_second_job}
                    nationality={driver.nationality}
                    car_type={driver.car_type}
                    car_year={driver.car_year}
                    car_model={driver.car_model}
                    gender={driver.gender}
                    birthdate={driver.birthdate ?? ""}
                />
                <Divider flexItem />
                {/* credit info  */}
                <DriverInfo driver={driver} setRerender={setRerender} />
                <Divider flexItem />
                {/* add trip or edit cash  */}
                <Stack marginTop="20px">
                    <Box>
                        <Button
                            variant="outlined"
                            onClick={() => setOpenChargeCashForm(true)}
                        >
                            تعديل الرصيد
                        </Button>
                        {/* <Button
                            variant="outlined"
                            onClick={() => setOpenAddTripForDriver(true)}
                            sx={{ marginLeft: "16px" }}
                        >
                            اضافة رحلة
                        </Button> */}
                    </Box>
                    <DriverChangeCash
                        openChargeCashForm={openChargeCashForm}
                        setOpenChargeCashForm={setOpenChargeCashForm}
                        driver={driver}
                        setRerender={setRerender}
                    />
                    {/* <AddTripForDriver
                        open={openAddTripForDriver}
                        setOpen={setOpenAddTripForDriver}
                        driverTrxId={driver?.driver_trx?.id}
                        driverId={driverId}
                    /> */}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default EditDriver;
