import * as yup from "yup";

export const editOfferSchema = yup.object().shape({
    details: yup.string().required("ادخل تفاصيل العرض"),
    amount: yup
        .number()
        .min(0.000000001, "الجائزة يجب ان تكون اكبر من الصفر")
        .required("ادخل قيمة الجائزة الخاصة بالعرض"),
    trips_goal: yup.number().min(0, "عدد الرحلات يجب ان يكون اكبر من الصفر"),
    expires_at: yup.string().required("يجب ادخال تاريخ انتهاء العرض"),
});
