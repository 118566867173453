import { useEffect, useState } from "react";
// axios
import EnhancedAxios from "./api/EnhancedAxios";
// router
import { Routes, Route } from "react-router-dom";
// components
import { ProtectedRoutes, AdminLayout, AuthLayout } from "./components";
// pages
import {
    // auth
    Login,
    // admin
    SuperAdminStatistics,
    Settings,
    Areas,
    EditArea,
    AddArea,
    Cities,
    AddCity,
    EditCity,
    Users,
    EditUser,
    Drivers,
    EditDriver,
    DriversCash,
    DriversJoinRequests,
    AddDriver,
    DriverRequest,
    Orders,
    OrderDetails,
    HelpRequests,
    Notification,
    Offers,
    Transactions,
    EditTransaction,
    SosCategories,
    AddSosCategory,
    EditSosCategory,
    EditDriverTransaction,
    ViewAllNotification,
    OtpMessagesHistory,
    TechnicalSettings,
    DriverCash,
    SupporterSosUsers,
    DriverTransactions,
    PrepaidCards,
} from "./pages";
import { Helmet } from "react-helmet";

function App() {
    const [metaTagsValues, setMetaTagsValues] = useState({
        title: "Arrivo Admin Dashboard",
        about: "",
    });
    useEffect(() => {
        EnhancedAxios(null, null)
            .get("/meta")
            .then((response) => {
                setMetaTagsValues({
                    about: response.data.settings.about,
                });
            });
    }, []);
    return (
        <>
            <Helmet>
                <title>
                    {metaTagsValues.title ?? "Arrivo Admin Dashboard"}
                </title>
                <meta
                    name="description"
                    content={metaTagsValues.about ?? "Arrivo Admin Dashboard"}
                />
                <meta
                    property="og:title"
                    content={metaTagsValues.title ?? "Arrivo Admin Dashboard"}
                />
                <meta
                    property="og:description"
                    content={metaTagsValues.about ?? "Arrivo Admin Dashboard"}
                />
                <meta property="og:image" content="./assets/logo3.svg" />
            </Helmet>
            <div className="App">
                <Routes>
                    <Route element={<ProtectedRoutes />}>
                        <Route element={<AdminLayout />}>
                            <Route
                                path="/"
                                element={<SuperAdminStatistics />}
                            />
                            {/* areas  */}
                            <Route path="/areas" element={<Areas />} />
                            <Route
                                path="/edit-area/:areaId"
                                element={<EditArea />}
                            />
                            <Route path="/add-area" element={<AddArea />} />
                            {/* cities  */}
                            <Route path="/cities" element={<Cities />} />
                            <Route path="/add-city" element={<AddCity />} />
                            <Route
                                path="/edit-city/:cityId"
                                element={<EditCity />}
                            />
                            {/* users */}
                            <Route path="/users" element={<Users />} />
                            <Route
                                path="/edit-user/:userId"
                                element={<EditUser />}
                            />
                            <Route
                                path="/users-sos-helper"
                                element={<SupporterSosUsers />}
                            />
                            {/* drivers  */}
                            <Route path="/drivers" element={<Drivers />} />
                            <Route
                                path="/edit-driver/:driverId"
                                element={<EditDriver />}
                            />
                            <Route
                                path="/drivers-cash"
                                element={<DriversCash />}
                            />
                            <Route path="/add-driver" element={<AddDriver />} />
                            <Route
                                path="/drivers-join-requests"
                                element={<DriversJoinRequests />}
                            />
                            <Route
                                path="/driver-request/:requestId"
                                element={<DriverRequest />}
                            />
                            {/* orders  */}
                            <Route path="/orders" element={<Orders />} />
                            <Route
                                path="/order-details/:orderId"
                                element={<OrderDetails />}
                            />
                            <Route
                                path="/drivers-cash/:trxId"
                                element={<DriverCash />}
                            />
                            {/* helps  */}
                            <Route
                                path="/help-requests"
                                element={<HelpRequests />}
                            />
                            <Route
                                path="/sos-categories"
                                element={<SosCategories />}
                            />
                            <Route
                                path="/add-sos-category"
                                element={<AddSosCategory />}
                            />
                            <Route
                                path="/edit-sos-category/:categoryId"
                                element={<EditSosCategory />}
                            />
                            {/* notification  */}
                            <Route
                                path="/notification"
                                element={<Notification />}
                            />
                            <Route
                                path="/view-all-notification"
                                element={<ViewAllNotification />}
                            />
                            {/* offers  */}
                            <Route path="/offers" element={<Offers />} />
                            {/* settings  */}
                            <Route path="/settings" element={<Settings />} />
                            <Route
                                path="/technical-settings"
                                element={<TechnicalSettings />}
                            />
                            {/* chargin  */}
                            <Route
                                path="/transactions"
                                element={<Transactions />}
                            />
                            <Route
                                path="/drivers-transactions"
                                element={<DriverTransactions />}
                            />
                            {/* otp info  */}
                            <Route
                                path="/otp-messages-history"
                                element={<OtpMessagesHistory />}
                            />
                            {/* prepaid cards  */}
                            <Route
                                path="/prepaid-cards"
                                element={<PrepaidCards />}
                            />
                        </Route>
                    </Route>
                    <Route element={<AuthLayout />}>
                        <Route path="/login" element={<Login />} />
                    </Route>
                </Routes>
            </div>
        </>
    );
}

export default App;
