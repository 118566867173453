import React, { useEffect, useState } from "react";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// form
import { useFormik } from "formik";
// mui
import { Stack, Box, TextField, Typography } from "@mui/material";
// components
import { RingsLoader, SubmitButton } from "../../components";
import SocialLinkForm from "./SocialLinkForm";
// alerts
import { errorAlert } from "../../utils/alerts";

// ------------------------------------ page_function_components ----------------------------
const SocialLinks = () => {
    const [isLoading, setIsLoading] = useState(true);
    const userToken = useSelector((state) => state.userToken);
    // get settings data
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/admin/settings?sorts=updated_at&page=1&paginate=9999`)
            .then((response) => {
                let allLinks = response.data.data;
                allLinks.forEach((el) => {
                    if (socialLinksWeNeedKeys.includes(el.key)) {
                        let currentSocialLinkIndex =
                            socialLinksWeNeedData.findIndex(
                                (obj) => obj.key === el.key
                            );
                        socialLinksWeNeedData[currentSocialLinkIndex].id =
                            el.id;
                        socialLinksWeNeedData[currentSocialLinkIndex].value =
                            el.value;
                    }
                });
                setIsLoading(false);
            })
            .catch((error) => errorAlert(error));
    }, []);

    return (
        <Stack gap="20px">
            <Typography variant="h5">معلومات التواصل</Typography>
            <Stack spacing={2} width="100%">
                {isLoading ? (
                    <RingsLoader />
                ) : (
                    socialLinksWeNeedData.map((item) => (
                        <SocialLinkForm
                            key={item.id}
                            itemKey={item.key}
                            value={item.value}
                            id={item.id}
                            title={item.title}
                            fieldType={item.fieldType}
                        />
                    ))
                )}
            </Stack>
        </Stack>
    );
};

export default SocialLinks;

// utils
const socialLinksWeNeedData = [
    {
        key: "app_twitter_url",
        title: "تويتر",
        fieldType: "url",
        id: 0,
        value: "",
    },
    {
        key: "app_facebook_url",
        title: "فيسبوك",
        fieldType: "url",
        id: 0,
        value: "",
    },
    {
        key: "app_instagram_url",
        title: "انستغرام",
        fieldType: "url",
        id: 0,
        value: "",
    },
    {
        key: "app_whatsapp_url",
        title: "واتساب",
        fieldType: "url",
        id: 0,
        value: "",
    },
    {
        key: "app_website_url",
        title: "الموفع الالكترونى",
        fieldType: "url",
        id: 0,
        value: "",
    },
    {
        key: "app_email_url",
        title: "البريد الالكترونى",
        fieldType: "email",
        id: 0,
        value: "",
    },
];
const socialLinksWeNeedKeys = [
    "app_twitter_url",
    "app_facebook_url",
    "app_instagram_url",
    "app_whatsapp_url",
    "app_website_url",
    "app_email_url",
];
