import { translateToArabic } from "../translate/englishToArabic";
import { IsResolvedSwitch } from "../../components";
import moment from "moment";
export const helpRequestsCols = [
    {
        field: "details",
        headerName: "التفاصيل",
        width: "280",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.details ?? "لا يوجد تفاصيل",
    },
    {
        field: "category",
        headerName: "فئة الطلب",
        width: "100",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.category?.name ?? "غير معروف",
    },
    {
        field: "statusTrip",
        headerName: "حالة الرحلة",
        width: "80",
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.trip?.status
                ? translateToArabic(params.row.trip.status)
                : "غير معروف",
    },
    {
        field: "clientName",
        headerName: "اسم العميل",
        width: "100",
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.trip?.customer?.name ?? "غير معروف",
    },
    {
        field: "clientPhone",
        headerName: "هاتف العميل",
        width: "100",
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.trip?.customer?.phone ?? "غير معروف",
    },
    {
        field: "driverName",
        headerName: "اسم السائق",
        width: "100",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.trip?.driver?.name ?? "غير معروف",
    },
    {
        field: "driverPhone",
        headerName: "هاتف السائق",
        width: "100",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.trip?.driver?.phone ?? "غير معروف",
    },
    {
        field: "created_at",
        headerName: "تاريخ انشاء الطلب",
        width: "100",
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.created_at
                ? moment(params.row.created_at).format("YYYY/MM/DD hh:mm")
                : "غير معروف",
    },
    {
        field: "is_resolved",
        headerName: "تم الحل",
        width: "60",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <IsResolvedSwitch
                id={params.row.id}
                is_resolved={params.row.status == "resolved" ? true : false}
            />
        ),
    },
];
