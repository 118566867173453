import * as yup from "yup";

// login validation
export const editAreaSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, "اسم المنطقة قصير , يجب ان يكون اسم المنطقة اكبر من 2 حروف")
    .required("ادخل اسم المنطقة!"),
  delivery_price: yup
    .number()
    .typeError("سعر التكلفة يجب ان يكون رقم")
    .required("ادخل سعر التكلفة!"),
  city_id: yup.string().required("ادخل المدينة التابع لها المنطقة"),
});
