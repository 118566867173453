// mui
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
// import rtlPlugin from 'stylis-plugin-rtl';
import { RtlTheme, cacheRtl } from "../../themes/RtlTheme";

const CacheThemeProvider = (props) => {
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={RtlTheme}>{props.children}</ThemeProvider>
    </CacheProvider>
  );
};

export default CacheThemeProvider;
