import React, { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// hooks
import { usePageParams } from "../../../hooks/usePageParams";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import { Stack, Box, Pagination, Typography } from "@mui/material";
// icon
import { MdAdd } from "react-icons/md";
// components
import {
    LabelCard,
    LinkButton,
    PageTitle,
    RingsLoader,
    SortBox,
    SearchBox,
    DisableStatusFilterBox,
    CustomPagination,
    CityFilter,
} from "../../../components";
// alerts
import { errorAlert } from "../../../utils/alerts";

const Areas = () => {
    const userToken = useSelector((state) => state.userToken);
    const { page, setPage } = usePageParams();
    const [loading, setLoading] = useState(true);
    const [areas, setAreas] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [totalItems, setTotalItems] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [sortValue, setSortValue] = useState("-created_at");
    const [filterValues, setFilterValues] = useState({
        city_id: "",
        status: "is_disabled:eq:false",
    });
    const [dataChanged, setDataChanged] = useState(false);
    // ------------------------------------------- get_all_areas ----------------------------------
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(
                `/admin/areas?page=${page}&paginate=${itemsPerPage}&q=${searchValue}&sorts=${sortValue}&filters=${filterValues.status},${filterValues.city_id}`
            )
            .then((response) => {
                setAreas(response.data.data);
                setTotalItems(response.data.meta.total);
                setLoading(false);
            })
            .catch((error) => errorAlert(error));
    }, [page, itemsPerPage, searchValue, sortValue, filterValues, dataChanged]);

    return (
        <Stack gap="20px">
            <PageTitle title="إدارة المناطق" />
            <Stack gap="20px">
                <LinkButton text="اضافة" to="/add-area" icon={<MdAdd />} />
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap="20px"
                    flexWrap="wrap"
                >
                    <SearchBox
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                    <SortBox
                        sortValue={sortValue}
                        setSortValue={setSortValue}
                    />
                    <DisableStatusFilterBox
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                    />
                    <CityFilter setFilterValues={setFilterValues} />
                </Box>
            </Stack>
            {loading ? (
                <RingsLoader />
            ) : (
                <Box
                    display="flex"
                    justifyContent="center"
                    gap="20px"
                    flexWrap="wrap"
                >
                    {areas.length ? (
                        areas.map((area) => (
                            <LabelCard
                                key={area.id}
                                label={area.name}
                                id={area.id}
                                apiPath={`/admin/areas/${area.id}`}
                                editPagePath={`/edit-area/${area.id}`}
                                setDataChanged={setDataChanged}
                            />
                        ))
                    ) : (
                        <Typography variant="body1">
                            لا يوجد مناطق لعرضها.
                        </Typography>
                    )}
                </Box>
            )}
            {loading ? (
                <></>
            ) : (
                <CustomPagination
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    currentPage={page}
                    setCurrentPage={setPage}
                />
            )}
        </Stack>
    );
};

export default Areas;
