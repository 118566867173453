import React, { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
//mui
import { Stack, Box, Divider } from "@mui/material";
// components
import {
    PageTitle,
    RingsLoader,
    StatisticsCard,
    TripPath,
} from "../../../components";
// sections
import { OtpInfo } from "../../../sections";
// alerts
import { errorAlert } from "../../../utils/alerts";

const SuperAdminStatistics = () => {
    const userToken = useSelector((state) => state.userToken);
    const [isLoading, setIsLoading] = useState(true);
    const [statistics, setStatistics] = useState({});
    // get statistics data
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/admin/statistics`)
            .then((response) => {
                setIsLoading(false);
                setStatistics(response.data);
            })
            .catch((error) => errorAlert(error));
    }, []);
    return (
        <Stack gap="20px">
            <PageTitle title="الصفحة الرئيسية" />
            {isLoading ? (
                <RingsLoader />
            ) : (
                <Box
                    display="flex"
                    gap="20px"
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="wrap"
                >
                    <StatisticsCard
                        text="عدد الزبائن"
                        number={statistics.total_users}
                    />
                    <StatisticsCard
                        text="عدد المندوبين"
                        number={statistics.total_drivers}
                    />
                    {/* <StatisticsCard
                        text="عدد الزبائن"
                        number={statistics.total_customers}
                    /> */}
                    <StatisticsCard
                        text="عدد الطلبات"
                        number={statistics.total_trips}
                    />
                </Box>
            )}
            <Divider />
            <OtpInfo />
        </Stack>
    );
};

export default SuperAdminStatistics;
