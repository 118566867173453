import { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// hooks
import { usePageParams } from "../../../hooks/usePageParams";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import { Stack, Typography, Box } from "@mui/material";
// components
import {
    PageTitle,
    SearchBox,
    SortBox,
    RingsLoader,
    PaymentMethodSelect,
} from "../../../components";
import { TableGrid } from "../../../sections";
// alerts
import { errorAlert } from "../../../utils/alerts";
// cols
import { transactionsCols } from "../../../utils/gridCols/transactionsCols";

const Transactions = () => {
    const userToken = useSelector((state) => state.userToken);
    const { page, setPage } = usePageParams();
    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [totalItems, setTotalItems] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [sortValue, setSortValue] = useState("-created_at");
    const [filterValues, setFilterValues] = useState({
        city_id: "",
        status: "is_disabled:eq:false",
        paymentMethod: "",
    });
    // ---------------------------- get_all_transactions ----------------------------
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(
                `/admin/wallet-transactions?page=${page}&paginate=${itemsPerPage}&sorts=${sortValue}&q=${searchValue}&payment_method=${filterValues.paymentMethod}`
            )
            .then((res) => {
                setTransactions(res.data.data);
                setTotalItems(res.data.meta.total);
                setLoading(false);
            })
            .catch((error) => errorAlert(error));
    }, [page, itemsPerPage, searchValue, sortValue, filterValues]);
    return (
        <Stack gap="20px">
            <PageTitle title="عمليات شحن المحافظ" />
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="20px"
                flexWrap="wrap"
            >
                <SearchBox
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
                <SortBox sortValue={sortValue} setSortValue={setSortValue} />
                {/* <PaymentMethodSelect filterValues={filterValues} setFilterValues={setFilterValues} /> */}
            </Box>
            <Stack>
                {loading ? (
                    <RingsLoader />
                ) : transactions.length === 0 && searchValue === "" ? (
                    <Typography variant="body1" textAlign="center">
                        لا يوجد عناصر لعرضها.
                    </Typography>
                ) : transactions.length === 0 && searchValue !== "" ? (
                    <Typography variant="body1" textAlign="center">
                        {" "}
                        لا يوجد عناصر لعرضها خاصة نتيجة البحث.
                    </Typography>
                ) : (
                    <TableGrid
                        rows={transactions}
                        cols={transactionsCols}
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        currentPage={page}
                        setCurrentPage={setPage}
                    />
                )}
            </Stack>
        </Stack>
    );
};

export default Transactions;
