import React, { useState } from "react";
// r redux
import { useSelector } from "react-redux";
// mui
import { Button } from "@mui/material";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// alerts
import {
    successAlert,
    askForConfirmationAlert,
    errorAlert,
} from "../../utils/alerts";

const DeleteJoinRequest = ({ id, name }) => {
    const userToken = useSelector((state) => state.userToken);
    const [isDeleted, setIsDeleted] = useState(false);
    const deleteUser = () => {
        EnhancedAxios(null, userToken)
            .delete(`/admin/driver-forms/${id}`)
            .then((res) => {
                successAlert("تم حذف المستخدم");
                setIsDeleted(true);
            })
            .catch((error) => errorAlert(error));
    };
    const confirmDeleting = () => {
        askForConfirmationAlert(
            `هل أنت متأكد انك تريد حذف  طلب السائق "${name}"`,
            deleteUser
        );
    };
    return (
        <Button
            color="error"
            variant="outlined"
            onClick={confirmDeleting}
            disabled={isDeleted}
        >
            {isDeleted ? "تم الحذف" : "حذف"}
        </Button>
    );
};

export default DeleteJoinRequest;
