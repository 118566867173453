import { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// hooks
import { usePageParams } from "../../../hooks/usePageParams";
// mui
import { Stack, Box, Typography } from "@mui/material";
// components
import {
    PageTitle,
    RingsLoader,
    SearchBox,
    SortBox,
    DisableStatusFilterBox,
} from "../../../components";
import { TableGrid } from "../../../sections";
import { usersCols } from "../../../utils/gridCols/usersCols";
// alers
import { errorAlert } from "../../../utils/alerts";
// icons
import { MdAdd } from "react-icons/md";
import EnhancedAxios from "../../../api/EnhancedAxios";

const SupporterSosUsers = () => {
    const userToken = useSelector((state) => state.userToken);
    const { page, setPage } = usePageParams();
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [totalItems, setTotalItems] = useState(24);
    const [searchValue, setSearchValue] = useState("");
    const [sortValue, setSortValue] = useState("-created_at");
    const [filterValues, setFilterValues] = useState({
        status: "is_disabled:eq:false",
    });
    // get all users
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(
                `admin/users?filters=roles:6,${filterValues.status}&page=${page}&paginate=${itemsPerPage}&q=${searchValue}&sorts=${sortValue}`
            )
            .then((res) => {
                setUsers(res.data.data);
                setTotalItems(res.data.meta.total);
                setLoading(false);
            })
            .catch((error) => errorAlert(error));
    }, [page, filterValues, searchValue, sortValue]);
    return (
        <Stack gap="20px">
            <PageTitle title="إدارة المستخدمين الداعمين لطلبات المساعدة" />
            <Stack gap="20px">
                <Box display="flex" gap="20px" flexWrap="wrap">
                    <SearchBox
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                    <SortBox
                        sortValue={sortValue}
                        setSortValue={setSortValue}
                    />
                    <DisableStatusFilterBox
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                    />
                </Box>
            </Stack>
            <Stack>
                {loading ? (
                    <RingsLoader />
                ) : users.length === 0 && searchValue === "" ? (
                    <Typography variant="body1" textAlign="center">
                        لا يوجد عناصر لعرضها.
                    </Typography>
                ) : users.length === 0 && searchValue !== "" ? (
                    <Typography variant="body1" textAlign="center">
                        {" "}
                        لا يوجد عناصر لعرضها خاصة نتيجة البحث.
                    </Typography>
                ) : (
                    <TableGrid
                        rows={users}
                        cols={usersCols}
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        currentPage={page}
                        setCurrentPage={setPage}
                    />
                )}
            </Stack>
        </Stack>
    );
};

export default SupporterSosUsers;
