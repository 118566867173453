import { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// mui
import {
    Stack,
    Box,
    Typography,
    Divider,
    Switch,
    Button,
    Tooltip,
} from "@mui/material";
// components
import { LinkButton } from "../../components";
import {
    askForConfirmationAlert,
    errorAlert,
    successAlert,
} from "../../utils/alerts";

const LabelCard = ({ label, id, apiPath, editPagePath, setDataChanged }) => {
    const [checked, setChecked] = useState(false);
    const userToken = useSelector((state) => state.userToken);

    // ---------------------- get_is_disbaled value ----------------------
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(apiPath)
            .then((response) => {
                setChecked(!response.data.is_disabled);
            });
    }, []);
    // ---------------------- change is_disbaled value ----------------------
    const handleChange = (e) => {
        const formData = new FormData();
        formData.append("is_disabled", !e.target.checked);

        EnhancedAxios("multipart/form-data", userToken)
            .put(apiPath, formData)
            .then((response) => {
                const alertMsg =
                    response.data.is_disabled === true
                        ? "تم التعطيل"
                        : "تم التفعيل";
                successAlert(alertMsg);
                setChecked(!response.data.is_disabled);
            })
            .catch((error) => {
                errorAlert(error);
            });
    };
    // ---------------------- delteing_item ----------------------
    const deletingProcess = () => {
        EnhancedAxios(null, userToken)
            .delete(apiPath)
            .then((res) => {
                successAlert("تم حذف العنصر");
                setDataChanged((prev) => !prev);
            })
            .catch((error) => errorAlert(error));
    };
    const deleteItem = () => {
        askForConfirmationAlert(
            "هل انت متاكد انك تريد حذف هذا العنصر",
            deletingProcess
        );
    };
    return (
        <Stack
            width="280px"
            minWidth="270px"
            flex="1"
            maxWidth="320px"
            p={2}
            spacing={2}
            alignItems="center"
            divider={<Divider flexItem />}
            sx={{
                borderRadius: "10px",
                boxShadow: 2,
                opacity: !checked ? "0.4" : "1.0",
            }}
        >
            <Tooltip title={label} arrow>
                <Typography variant="h6">
                    {label.length > 16 ? `${label.slice(0, 16)}...` : label}
                </Typography>
            </Tooltip>
            <Box width="100%" display="flex" justifyContent="space-between">
                <Stack spacing={1} direction="row">
                    <LinkButton
                        to={editPagePath}
                        text="تعديل"
                        size="small"
                        variant="outlined"
                    />
                    {/* <Button size="small" onClick={deleteItem}>
                        حذف
                    </Button> */}
                </Stack>
                <Switch
                    size="small"
                    checked={checked}
                    onChange={handleChange}
                />
            </Box>
        </Stack>
    );
};

export default LabelCard;
