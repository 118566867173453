import {
    DeleteUser,
    LinkButton,
    ToggleStatus,
    UserTesterToggler,
    VerifySwitch,
} from "../../components";
import { translateToArabic } from "../translate/englishToArabic";
import moment from "moment";
export const usersCols = [
    {
        field: "name",
        headerName: "اسم المستخدم",
        width: "200",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.name ?? "غير معروف",
    },
    {
        field: "phone",
        headerName: "رقم الهاتف",
        width: "160",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.phone ?? "غير معروف",
    },
    {
        field: "gender",
        headerName: "النوع",
        width: "140",
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.gender
                ? translateToArabic(params.row.gender)
                : "غير معروف",
    },
    {
        field: "created_at",
        headerName: "تاريخ الانشاء",
        width: "180",
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.created_at
                ? moment(params.row.created_at).format("YYYY/MM/DD hh:mm")
                : "غير معروف",
    },
    {
        field: "showDetails",
        headerName: "مشاهدة",
        width: "100",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <LinkButton
                text="مشاهدة"
                to={`/edit-user/${params.row?.id}`}
                size="small"
                variant="outlined"
            />
        ),
    },
    {
        field: "toggleStatus",
        headerName: "مفعل/غير مفعل",
        width: "80",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <ToggleStatus
                id={params.row.id}
                is_disabled={params.row.is_disabled}
                putApiUrl={`admin/users/${params.row.id}`}
            />
        ),
    },
    {
        field: "is_verify",
        headerName: "تحقق الهاتف",
        width: "80",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <VerifySwitch
                id={params.row?.id}
                is_verified={params?.row?.is_verified}
            />
        ),
    },
    {
        field: "is_tester",
        headerName: "الوصول المبكر",
        width: "80",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <UserTesterToggler
                id={params.row?.id}
                is_tester={params?.row?.is_tester}
            />
        ),
    },
    {
        field: "delete_user",
        headerName: "حذف",
        width: "60",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <DeleteUser id={params?.row?.id} name={params?.row?.name ?? ""} />
        ),
    },
];
