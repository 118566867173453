import React from "react";
import { Typography } from "@mui/material";
const PageTitle = ({ title }) => {
  return (
    <Typography
      variant="h3"
      fontWeight="500"
      color="textColor.main"
      textAlign="center"
      marginBottom="20px"
    >
      {title}
    </Typography>
  );
};

export default PageTitle;
