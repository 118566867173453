import React, { useState } from "react";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// mui
import { TextField, Box, InputAdornment, Stack } from "@mui/material";
// component
import { SubmitButton } from "../../components";
// alerts
import { errorAlert, successAlert } from "../../utils/alerts/index";

import { Formik } from "formik";

const SocialLinkForm = ({
    id,
    itemKey,
    value,
    title,
    fieldType,
    schema,
    moreInfo = "",
}) => {
    const [newValue, setNewValue] = useState(value);
    const usertoken = useSelector((state) => state.userToken);
    // change input
    // const handleChange = (e)=> {
    //     setNewValue(e.target.value);
    // }
    // submit changing
    const submitEdit = (values) => {
        const formData = new FormData();
        formData.append("value", values.value);
        EnhancedAxios("multipart/form-data", usertoken)
            .put(`/admin/settings/${id}`, formData)
            .then((res) => {
                successAlert(`تم تعديل  ${title}`);
            })
            .catch((error) => errorAlert(error));
    };
    return (
        <Formik
            initialValues={{ value: newValue }}
            validationSchema={schema}
            onSubmit={submitEdit}
        >
            {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
                <Stack>
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        gap="20px"
                        flexWrap="wrap"
                        component="form"
                        onSubmit={handleSubmit}
                    >
                        <TextField
                            type={fieldType}
                            name={"value"}
                            value={values.value}
                            label={title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            sx={{ flex: "1", minWidth: "300px" }}
                            error={errors.value}
                            helperText={errors.value && errors.value}
                        />
                        <SubmitButton text="تعديل" />
                    </Box>
                    {moreInfo ? (
                        <p
                            style={{
                                fontSize: "12px",
                                paddingRight: "16px",
                                color: "#555",
                            }}
                        >
                            {moreInfo}
                        </p>
                    ) : (
                        <></>
                    )}
                </Stack>
            )}
        </Formik>
    );
};

export default SocialLinkForm;
