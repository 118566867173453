import React, { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// form
import { useFormik } from "formik";
// mui
import { Stack, Box, TextField, Typography } from "@mui/material";
// components
import { RingsLoader, SubmitButton } from "../../components";
import SocialLinkForm from "./SocialLinkForm";
// alerts
import { errorAlert } from "../../utils/alerts";
// yup
import * as yup from "yup";

const OtpOptions = () => {
    const userToken = useSelector((state) => state.userToken);
    const [isLoading, setIsLoading] = useState(true);
    // get settings data
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/admin/settings?sorts=updated_at&page=1&paginate=9999&q=otp`)
            .then((response) => {
                let allOptions = response.data.data;
                allOptions.forEach((el) => {
                    if (optionsWeNeedKeys.includes(el.key)) {
                        let currentOptionIndex = optionsWeNeedData.findIndex(
                            (obj) => obj.key === el.key
                        );
                        optionsWeNeedData[currentOptionIndex].id = el.id;
                        optionsWeNeedData[currentOptionIndex].value = el.value;
                    }
                });
                setIsLoading(false);
            })
            .catch((error) => errorAlert(error));
    }, []);
    return (
        <Stack gap="20px">
            <Typography variant="h5">خيارات نظام الرسائل</Typography>
            {isLoading ? (
                <RingsLoader />
            ) : (
                optionsWeNeedData.map((opt) => (
                    <SocialLinkForm
                        key={opt.id}
                        id={opt.id}
                        itemKey={opt.key}
                        value={opt.value}
                        title={opt.title}
                        fieldType={opt.fieldType}
                        schema={opt.schema}
                    />
                ))
            )}
        </Stack>
    );
};

export default OtpOptions;
// utils
const optionsWeNeedData = [
    {
        key: "sadeem_otp_url",
        title: "رابط  OTP",
        id: 0,
        value: "",
        fieldType: "url",
        schema: yup.object().shape({
            value: yup
                .string()
                .url("ادخل رابط بشكل صحيح")
                .required("ادخل القيمة الرابط المراد التعديل اليها"),
        }),
    },
    {
        key: "sadeem_otp_key",
        title: "مفتاح  OTP",
        id: 0,
        value: "",
        fieldType: "text",
        schema: yup.object().shape({
            value: yup
                .string()
                .required("ادخل القيمة المفتاح المراد التعديل اليها"),
        }),
    },
    {
        key: "sadeem_otp_jwt",
        title: "OTP Token",
        id: 0,
        value: "",
        fieldType: "text",
        schema: yup.object().shape({
            value: yup
                .string()
                .required("ادخل القيمة token المراد التعديل اليها"),
        }),
    },
    {
        key: "sadeem_otp_env",
        title: "OTP بيئة",
        id: 0,
        value: "",
        fieldType: "text",
        schema: yup.object().shape({
            value: yup
                .string()
                .required("ادخل القيمة بيئة المراد التعديل اليها"),
        }),
    },
];
const optionsWeNeedKeys = [
    "sadeem_otp_url",
    "sadeem_otp_key",
    "sadeem_otp_jwt",
    "sadeem_otp_env",
];
