import React, { useState } from "react";
import {
    GoogleMap,
    Polyline,
    Marker,
    useLoadScript,
} from "@react-google-maps/api";
import { useEffect } from "react";

const mapContainerStyle = {
    width: "100%",
    height: "400px",
};
const TripPath = ({ path }) => {
    const [pathArrObj, setPathArrObj] = useState([]);
    const { isLoaded, error } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    }); // script loader for map
    useEffect(() => {
        setPathArrObj(convertCoordsArrToLatLngObj(path));
    }, []);
    return pathArrObj.length && isLoaded ? (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={14}
            center={pathArrObj[0]} // Set the initial center of the map to the first coordinate
        >
            <Polyline
                path={pathArrObj}
                options={{
                    strokeColor: "#5e2e90", // Color of the line
                    strokeOpacity: 1,
                    strokeWeight: 4, // Width of the line
                }}
            />
            <Marker position={pathArrObj[0]} label="نقطة الانطلاق" />
            <Marker
                position={pathArrObj[pathArrObj.length - 1]}
                label="نقطة الوصول"
            />
        </GoogleMap>
    ) : (
        <></>
    );
};
export default TripPath;

const convertCoordsArrToLatLngObj = (coords) => {
    let result = [];
    if (coords.length) {
        coords.map((point) => {
            const obj = { lat: point[1], lng: point[0] };
            result.push(obj);
        });
    }
    return result;
};
