import { useState, useCallback, useEffect } from "react";
import { useLoadScript, GoogleMap, Marker } from "@react-google-maps/api";

const UserLocation = ({ points }) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    const [map, setMap] = useState(null);
    const [center, setCenter] = useState(points);

    const onLoad = useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map);
    }, []);

    const onUnmount = useCallback(function callback(map) {
        setMap(null);
    }, []);

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={points}
            zoom={11}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <Marker position={points} />
        </GoogleMap>
    ) : (
        <></>
    );
};

export default UserLocation;

const containerStyle = {
    width: "100%",
    height: "400px",
};
