import {
    LinkButton,
    ToggleStatus,
    ApproveDriverJoinRequest,
    TemporarilyHoldToggler,
    DeleteJoinRequest,
} from "../../components";
import { Link } from "react-router-dom";
import moment from "moment";
import { translateToArabic } from "../translate/englishToArabic";
export const driverJoinRequestsCols = [
    {
        field: "name",
        headerName: "اسم المستخدم",
        width: "250",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.name ?? "غير معروف",
    },
    {
        field: "phone",
        headerName: "رقم الهاتف",
        width: "150",
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.phone ?? "غير معروف",
    },
    {
        field: "gender",
        headerName: "النوع",
        width: "80",
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.gender
                ? translateToArabic(params.row.gender)
                : "غير معروف",
    },
    {
        field: "created_at",
        headerName: "تاريخ انشاء الطلب",
        width: "160",
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.created_at
                ? moment(params.row.created_at).format("YYYY/MM/DD hh:mm")
                : "غير معروف",
    },
    {
        field: "toggleStatus",
        headerName: "مرفرض/غير مرفوض",
        width: "70",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <TemporarilyHoldToggler
                id={params.row.id}
                is_rejected={params.row.is_rejected ?? true}
                putApiUrl={`admin/driver-forms/${params.row.id}`}
            />
        ),
    },
    {
        field: "showDetails",
        headerName: "مشاهدة",
        width: "100",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <LinkButton
                text="مشاهدة"
                to={`/driver-request/${params.row?.id}`}
                size="small"
                variant="outlined"
            />
        ),
    },
    {
        field: "approveRequest",
        headerName: "قبول الطلب",
        width: "100",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <ApproveDriverJoinRequest id={params?.row?.id} />
        ),
    },
    {
        field: "deleteRequest",
        headerName: "حذف الطلب",
        width: "100",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <DeleteJoinRequest id={params?.row?.id} name={params?.row?.name} />
        ),
    },
];
