// react router
import { useNavigate } from "react-router";
// redux h
import { useSelector, useDispatch } from "react-redux";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// mui
import { Button, Box, Typography } from "@mui/material";
// icons
import { MdLogout } from "react-icons/md";
// alerts
import { askForConfirmationAlert, errorAlert } from "../../utils/alerts/index";
// redux actions
import { removeTokenFromLocal } from "../../redux/actions/userActions";

const LogoutButton = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logoutProcess = () => {
        EnhancedAxios(null, null)
            .get("/logout")
            .then((response) => {
                // console.log(response);
            })
            .catch((error) => {
                // errorAlert(error.message);
            })
            .finally(() => {
                dispatch(removeTokenFromLocal());
                navigate("/login");
            });
        return 0;
    };
    const handleLogout = () => {
        askForConfirmationAlert(
            "هل انت متأكد انك تريد تسجيل الخروج ؟",
            logoutProcess
        );
    };
    return (
        <Box
            component={Button}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            gap="10px"
            sx={{
                color: "inherit",
                padding: "8px",
                borderRadius: "5px",
                transition: ".3s ease",
                "&:hover": {
                    backgroundColor: "primary.light",
                },
            }}
            onClick={handleLogout}
        >
            <Typography component="span">
                <MdLogout />
            </Typography>
            <Typography component="span">تسجيل الخروج</Typography>
        </Box>
    );
};

export default LogoutButton;
