import { useState } from "react";
// formik
import { useFormik } from "formik";
// react router
import { useNavigate } from "react-router";
// react redux
import { useDispatch, useSelector } from "react-redux";
// mui
import {
    Stack,
    TextField,
    InputAdornment,
    IconButton,
    FormControl,
    OutlinedInput,
    InputLabel,
} from "@mui/material";
// components & secionts
import { SubmitButton } from "../../components";
import { ErrorsStack } from "../../sections";
// redux actions
import {
    setUserToken,
    setCurrentUserData,
} from "../../redux/actions/userActions";
//axios
import EnhancedAxios from "../../api/EnhancedAxios";
//validation schema
import { loginSchema } from "../../schemes/auth/loginSchema.js";
// icons
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
// alerts
import { errorAlert } from "../../utils/alerts";

const LoginForm = () => {
    // ---------- states
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userToken = useSelector((state) => state.userToken);
    const userData = useSelector((state) => state.userData);
    // ---------- toggle show password
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    // ---------- submit form
    const onSubmit = (values) => {
        const formData = new FormData();
        for (let i in values) {
            formData.append(i, values[i]);
        }
        EnhancedAxios("multipart/form-data", null)
            .post("/login", formData)
            .then((response) => {
                dispatch(setUserToken(response.data.token.value));
                EnhancedAxios(null, response.data.token.value)
                    .get("/me")
                    .then((res) => {
                        dispatch(setCurrentUserData(res.data));
                        navigate("/");
                    })
                    .catch((error) => console.log(error));
            })
            .catch((error) => {
                errorAlert(error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };
    // ---------- Formik Configration
    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
    } = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: loginSchema,
        onSubmit, //function we write above
    });

    return (
        <Stack
            component="form"
            onSubmit={handleSubmit}
            spacing={2}
            width="100%"
        >
            {/* email field  */}
            <TextField
                type="email"
                name="email"
                label="البريد الالكترونى"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            {/* password field  */}
            <FormControl>
                <InputLabel htmlFor="outlined-adornment-password">
                    الرقم السري
                </InputLabel>
                <OutlinedInput
                    type={showPassword ? "text" : "password"}
                    name="password"
                    label="الرقم السري"
                    variant="outlined"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? (
                                    <MdVisibilityOff />
                                ) : (
                                    <MdVisibility />
                                )}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            {/* submit form  */}
            <SubmitButton text="تسجيل الدخول" disabled={isSubmitting} />
            <ErrorsStack errors={errors} touched={touched} />
        </Stack>
    );
};

export default LoginForm;
