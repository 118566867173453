import React from "react";
// mui
import { Stack, Typography } from "@mui/material";

const StatisticsCard = ({ text, number, moreStyles = {} }) => {
    return (
        <Stack
            minWidth="270px"
            // flex="1"
            alignItems="center"
            p={4}
            sx={{
                backgroundColor: "primary.main",
                borderRadius: "10px",
                color: "basicColor.white",
                minWidth: "290px",
                ...moreStyles,
            }}
        >
            <Typography variant="h6">{text}</Typography>
            <Typography variant="h3">{number}</Typography>
        </Stack>
    );
};

export default StatisticsCard;
