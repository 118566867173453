export const lightPalette = {
  primary: {
    main: "#5e2e90",
  },
  secondary: {
    main: "#A1A054",
  },
  textColor: {
    main: "#000",
    secColor: "#999",
    invertColor: "#fff",
  },
  basicColor: {
    white: "#fff",
    black: "#000",
  },
};
