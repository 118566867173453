import React from "react";
// mui
import { Stack } from "@mui/material";
// components
import { PageTitle } from "../../../components";
import { OtpOptions } from "../../../sections";

function Settings() {
    return (
        <Stack gap="20px" alignItems="center">
            <PageTitle title="الضبط الفنى" />
            <Stack gap="20px" width="100%" maxWidth="700px">
                <OtpOptions />
            </Stack>
        </Stack>
    );
}

export default Settings;
