import { useState } from "react";
// mui
import {
    Stack,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
} from "@mui/material";

const TransactionTypeSelect = ({ values, setValues, isRequired = true }) => {
    const [type, setType] = useState(values.type ?? "");
    const handleChange = (e) => {
        setType(e.target.value);
        setValues((prev) => ({ ...prev, type: e.target.value }));
    };
    return (
        <FormControl fullWidth>
            <InputLabel>نوع العملية</InputLabel>
            <Select
                value={type}
                label="نوع العملية"
                onChange={handleChange}
                required={isRequired}
            >
                {transactionTypes.map((type, index) => (
                    <MenuItem key={index} value={type.value}>
                        {type.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default TransactionTypeSelect;

const transactionTypes = [
    {
        label: "ايداع",
        value: "deposit",
    },
    {
        label: "سحب",
        value: "withdraw",
    },
];
