import React, { useState, useEffect } from "react";
// react redux
import { useSelector } from "react-redux";
// hooks
import { usePageParams } from "../../../hooks/usePageParams";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// components
import {
    CustomPagination,
    OldMessageCard,
    PageTitle,
    RingsLoader,
    SortBox,
    SearchBox,
} from "../../../components";
// mui
import { Stack, Box, Typography } from "@mui/material";
// alerts
import { errorAlert } from "../../../utils/alerts";

const OtpMessagesHistory = () => {
    const userToken = useSelector((state) => state.userToken);
    // fetching states
    const { page, setPage } = usePageParams();
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [totalItems, setTotalItems] = useState(40);
    const [sortValue, setSortValue] = useState("-created_at");
    const [searchValue, setSearchValue] = useState("");
    // ------------------------------------------- get_all_messages ----------------------------------
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(
                `/admin/otp/history?page=${page}&paginate=${itemsPerPage}&q=${searchValue}&sorts=${sortValue}&filters=env:production`
            )
            .then((response) => {
                setMessages(response.data.data);
                setTotalItems(response.data.meta.total);
                setLoading(false);
            })
            .catch((error) => errorAlert(error));
    }, [page, itemsPerPage, searchValue, sortValue]);
    return (
        <Stack gap="20px">
            <PageTitle title="سجل الرسائل المرسلة" />
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="20px"
                flexWrap="wrap"
            >
                <SearchBox
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
                <SortBox sortValue={sortValue} setSortValue={setSortValue} />
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="20px"
                flexWrap="wrap"
            >
                {loading ? (
                    <RingsLoader />
                ) : messages.length === 0 && searchValue === "" ? (
                    <Typography variant="body1">
                        لا يوجد رسائل لعرضها.
                    </Typography>
                ) : messages.length === 0 && searchValue !== "" ? (
                    <Typography variant="body1">
                        لا يوجد رسائل لعرضها خاصة نتيجة البحث.
                    </Typography>
                ) : (
                    messages.map((msg) => (
                        <OldMessageCard
                            key={msg.id}
                            phone={msg?.body?.phone}
                            content={msg?.body?.content}
                        />
                    ))
                )}
            </Box>
            {loading ? (
                <></>
            ) : (
                <CustomPagination
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    currentPage={page}
                    setCurrentPage={setPage}
                />
            )}
        </Stack>
    );
};

export default OtpMessagesHistory;
