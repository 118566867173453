import React from "react";
// react router
import { Link } from "react-router-dom";
// mui
import { Button } from "@mui/material";

const LinkButton = ({
  text = "اضافة",
  to = "/",
  size = "large",
  variant = "contained",
  icon = null,
}) => {
  return (
    <Button
      size={size}
      variant={variant}
      color="primary"
      component={Link}
      to={to}
      endIcon={icon ?? icon}
      sx={{ width: "fit-content", boxShadow: "0" }}
      disableElevation
      disableRipple
      disableFocusRipple
      disableTouchRipple
    >
      {text}
    </Button>
  );
};

export default LinkButton;
