import React, { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// form lib
import { useFormik } from "formik";
// mui
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Stack,
    TextField,
} from "@mui/material";
// components
import { PageTitle, RingsLoader, SubmitButton } from "../../components";
// section
import { ErrorsStack } from "../../sections";
// schema
import { editAdminSchema } from "../../schemes/admin/editAdminSchema";
// alerts
import { successAlert, errorAlert } from "../../utils/alerts";

const EditAdminData = ({ open, handleClose }) => {
    const userToken = useSelector((state) => state.userToken);
    const [adminData, setAdminData] = useState({});
    const [loading, setLoading] = useState(true);
    // get current admin data
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(`/me`)
            .then((res) => {
                setAdminData(res.data);
                setLoading(false);
                handleClose();
            })
            .catch((error) => errorAlert(error));
    }, []);
    // ---------- submit_form_function ----------
    const onSubmit = (values) => {
        EnhancedAxios("application/json", userToken)
            .put(`/admin/users/${adminData.id}`, values)
            .then((res) => {
                successAlert("تم تعديل كلمة المرور خاصة الادارة ");
                setSubmitting(false);
            })
            .catch((error) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    // ---------- form_configration ----------
    const {
        values,
        setValues,
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
    } = useFormik({
        initialValues: {
            password: "",
            password_confirmation: "",
        },
        validationSchema: editAdminSchema,
        onSubmit,
    });

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>تعديل كلمة مرور الادراة</DialogTitle>
            {loading ? (
                <RingsLoader />
            ) : (
                <DialogContent>
                    <Stack
                        spacing={1}
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ paddingTop: "14px" }}
                    >
                        <TextField
                            type="email"
                            name="email"
                            label="البريد الالكترونى"
                            value={adminData.email}
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                        <TextField
                            type="password"
                            name="password"
                            label="الرقم السري"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <TextField
                            type="password"
                            name="password_confirmation"
                            label=" تأكيد الرقم السري"
                            value={values.password_confirmation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <SubmitButton text="حفظ" disabled={isSubmitting} />
                        <ErrorsStack errors={errors} touched={touched} />
                    </Stack>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default EditAdminData;
