import React, { useState, useEffect } from "react";
// react redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// components
import {
    LinkButton,
    MessageCountCard,
    PageTitle,
    RingsLoader,
    StatisticsCard,
} from "../../components";
// mui
import { Stack, Box, Button } from "@mui/material";
// icons
import { TbMessage2Up, TbMessage2Search } from "react-icons/tb";
// alerts
import { errorAlert } from "../../utils/alerts";

const OtpInfo = () => {
    const userToken = useSelector((state) => state.userToken);
    const [isLoading, setIsLoading] = useState(true);
    const [otpInfo, setOtpInfo] = useState({});
    // get statistics data
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/admin/otp/me`)
            .then((response) => {
                setIsLoading(false);
                setOtpInfo(response.data);
            })
            .catch((error) => errorAlert(error));
    }, []);
    return (
        <Stack>
            {isLoading ? (
                <RingsLoader />
            ) : (
                <>
                    <Box
                        display="flex"
                        gap="20px"
                        justifyContent="center"
                        alignItems="center"
                        flexWrap="wrap"
                    >
                        <StatisticsCard
                            text={"عدد الرسائل المرسلة"}
                            number={otpInfo?.prod_calls}
                        />
                        <StatisticsCard
                            text={"الرصيد المتبقى"}
                            number={otpInfo?.wallet?.credit}
                            moreStyles={{
                                background:
                                    Number(otpInfo?.wallet?.credit) < 10
                                        ? "red"
                                        : "",
                            }}
                        />
                    </Box>
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        marginTop="20px"
                    >
                        <LinkButton
                            variant="outlined"
                            to="/otp-messages-history"
                            text="سجل الرسائل المرسلة"
                        />
                    </Box>
                </>
            )}
        </Stack>
    );
};

export default OtpInfo;
