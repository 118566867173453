import { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// hooks
import { usePageParams } from "../../../hooks/usePageParams";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import { Stack, Box, Typography } from "@mui/material";
// components
import {
    PageTitle,
    RingsLoader,
    SearchBox,
    SortBox,
    DisableStatusFilterBox,
    CustomPagination,
    NotificationCard,
} from "../../../components";

const ViewAllNotification = () => {
    const userToken = useSelector((state) => state.userToken);
    const { page, setPage } = usePageParams();
    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(24);
    const [totalItems, setTotalItems] = useState(40);
    const [sortValue, setSortValue] = useState("-created_at");
    const [searchValue, setSearchValue] = useState("");
    const [filterValues, setFilterValues] = useState({
        status: "is_disabled:eq:false",
    });
    const [dataChanged, setDataChanged] = useState(false);
    // ------------------------------------------- get_all_areas ----------------------------------
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(
                `/admin/fcm-notifications?page=${page}&paginate=${itemsPerPage}&q=${searchValue}&sorts=${sortValue}&filters=${filterValues.status},topic:all`
            )
            .then((response) => {
                setNotifications(response.data.data);
                setTotalItems(response.data.meta.total);
                setLoading(false);
            })
            .catch((error) => console.log(error));
    }, [page, itemsPerPage, searchValue, sortValue, filterValues, dataChanged]);
    return (
        <Stack gap="20px">
            <PageTitle title="الاشعارات المرسلة" />
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="20px"
                flexWrap="wrap"
            >
                <SearchBox
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
                <SortBox sortValue={sortValue} setSortValue={setSortValue} />
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="20px"
                flexWrap="wrap"
            >
                {loading ? (
                    <RingsLoader />
                ) : notifications.length === 0 && searchValue === "" ? (
                    <Typography variant="body1">
                        لا يوجد عناصر لعرضها.
                    </Typography>
                ) : notifications.length === 0 && searchValue !== "" ? (
                    <Typography variant="body1">
                        {" "}
                        لا يوجد عناصر لعرضها خاصة نتيجة البحث.
                    </Typography>
                ) : (
                    notifications.map((notificationDetails) => (
                        <NotificationCard
                            notificationDetails={notificationDetails}
                        />
                    ))
                )}
            </Box>
            {loading ? (
                <></>
            ) : (
                <CustomPagination
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    currentPage={page}
                    setCurrentPage={setPage}
                />
            )}
        </Stack>
    );
};

export default ViewAllNotification;
