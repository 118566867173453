import * as yup from "yup";

const maxImgSizeMB = 2;
const maxImgSizeBytes = maxImgSizeMB * 2 ** 20;

export const editCategorySchema = yup.object().shape({
    img: yup
        .mixed()
        .nullable()
        .test(
            "fileSize",
            `حجم الصورة كبير جدا يجب الا يتعدى حجد الصورة ${maxImgSizeMB}MB`,
            (value) => imageEditSizeValidate(value)
        )
        .test("fileType", "امتداد الصورة يجب ان يكون jpg , png", (value) =>
            imageEditTypeValidate(value)
        ),
    name: yup.string().required("من فضلك ادخل اسم الفئة التى تريد اضافتها"),
    is_disabled: yup.boolean(),
});

// function to validate image if it get as url or not uploaded
const imageEditSizeValidate = (value) => {
    if (value != null && typeof value != "string") {
        return value.size <= maxImgSizeBytes;
    }
    return true;
};

const imageEditTypeValidate = (value) => {
    if (value != null && typeof value != "string") {
        return ["image/jpg", "image/png", "image/jpeg"].includes(value.type);
    }
    return true;
};
