import React from "react";
import { Button, Typography } from "@mui/material";
const SubmitButton = ({ text, disabled = false }) => {
  return (
    <Button
      size="large"
      variant="contained"
      color="primary"
      type="submit"
      height="100%"
      disabled={disabled}
    >
      <Typography variant="body1" color="basicColor.white">
        {text}
      </Typography>
    </Button>
  );
};

export default SubmitButton;
