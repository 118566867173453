import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import EnhancedAxios from "../../api/EnhancedAxios";
import { successAlert, errorAlert } from "../../utils/alerts";
const options = [
    {
        label: "مطلوبة",
        value: "requested",
    },
    {
        label: "مكتملة",
        value: "completed",
    },
    {
        label: "ملغية",
        value: "cancelled",
    },
    // {
    //     label: "ممتدة",
    //     value: "extended",
    // },
];
const ChangeTripStatus = ({ value, orderId }) => {
    const [status, setStatus] = useState(value ?? null);
    const userToken = useSelector((state) => state.userToken);

    const handleChange = (e) => {
        setStatus(e.target.value);
        const formData = new FormData();
        formData.append("status", e.target.value);
        EnhancedAxios("multipart/form-data", userToken)
            .put(`/admin/trips/${orderId}`, formData)
            .then((res) => {
                successAlert("تم تعديل حالة الرحلة");
            })
            .catch((error) => {
                errorAlert("حدث خطآ اثناء تغيير حالة الطلب خاول في وقت لاحق");
            });
    };
    return (
        <FormControl minWidth="270px" sx={{ flex: "1", minWidth: "48%" }}>
            <InputLabel>الحالة</InputLabel>
            <Select value={status} label="الحالة" onChange={handleChange}>
                {options.map((opt, index) => (
                    <MenuItem key={opt.value} value={opt.value}>
                        {opt.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default ChangeTripStatus;
