import React from "react";
import { Outlet } from "react-router-dom";
// mui component
import { Box, Container } from "@mui/material";

const AuthLayout = () => {
  return (
    <Box
      sx={{ flex: "1", maxHeight: "100vh", overflow: "auto" }}
      className="hide-scrollbar"
    >
      <Container>
        <Box sx={{ flex: "1" }}>
          <Outlet />
        </Box>
      </Container>
    </Box>
  );
};

export default AuthLayout;
