import React, { useState, useRef, useCallback, useEffect } from "react";
import {
    useLoadScript,
    GoogleMap,
    Polygon,
    Marker,
} from "@react-google-maps/api";
const polygonOptions = {
    fillColor: "#00FF00",
    fillOpacity: 0.5,
    strokeColor: "#000000",
    strokeOpacity: 1,
    strokeWeight: 1,
    zIndex: 2,
};

function AnotherMap({ values, setValues }) {
    const [path, setPath] = useState([]); // default paths
    const [center, setCenter] = useState({}); // map center
    const polygonRef = useRef(null); // ref to assign polygon to the map
    const listenersRef = useRef([]); // to handle events on the polygon
    const { isLoaded, error } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    }); // script loader for map
    // Call setPath with new edited path
    const onEdit = useCallback(() => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current
                .getPath()
                .getArray()
                .map((latLng) => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });
            setPath(nextPath);
            convertCoordsLatLngObjToArr(setValues, nextPath);
        }
    }, [setPath]);
    // Bind refs to current Polygon and listeners
    const onLoad = useCallback(
        (polygon) => {
            polygonRef.current = polygon;
            const path = polygon.getPath();
            listenersRef.current.push(
                path.addListener("set_at", onEdit),
                path.addListener("insert_at", onEdit),
                path.addListener("remove_at", onEdit)
            );
        },
        [onEdit]
    );
    // Clean up refs
    const onUnmount = useCallback(() => {
        listenersRef.current.forEach((lis) => lis.remove());
        polygonRef.current = null;
    }, []);
    // assign area coordinates to setPath state
    useEffect(() => {
        const path = convertCoordsArrToLatLngObj(values.geom.coordinates);
        setPath(path);
        setCenter(path[0]);
    }, []);
    // delete last point on the array of points when right click on the map
    const handlePolygonRightClick = (index) => {
        const newPath = [...path];
        newPath.splice(index, 1);
        setPath(newPath);
        convertCoordsLatLngObjToArr(setValues, newPath);
    };
    // delete point on the marker
    const onPointRightClick = (index) => {
        // Remove the point at the specified index from the polygon points
        const updatedPoints = path.filter((_, i) => i !== index);
        setPath(updatedPoints);
        convertCoordsLatLngObjToArr(setValues, updatedPoints);
    };
    return isLoaded ? (
        <div className="App">
            <GoogleMap
                mapContainerClassName="App-map"
                center={
                    center
                        ? center
                        : { lat: 52.52047739093263, lng: 13.36653284549709 }
                }
                zoom={15}
                version="weekly"
                on
            >
                <Polygon
                    editable
                    draggable
                    paths={path}
                    option={polygonOptions}
                    onMouseUp={onEdit}
                    onDragEnd={onEdit}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    // onRightClick={handlePolygonRightClick}
                />
                {path.length ? (
                    path.map((p, index) => (
                        <Marker
                            position={p}
                            onRightClick={() => onPointRightClick(index)}
                        />
                    ))
                ) : (
                    <></>
                )}
            </GoogleMap>
        </div>
    ) : (
        <></>
    );
}

export default AnotherMap;

const convertCoordsArrToLatLngObj = (coords) => {
    let result = [];
    if (coords.length) {
        coords[0].map((point) => {
            const obj = { lat: point[1], lng: point[0] };
            result.push(obj);
        });
    }
    return result;
};

const convertCoordsLatLngObjToArr = (setValues, coords) => {
    let result = [];
    coords.forEach((el) => {
        const smArr = [el.lng, el.lat];
        result.push(smArr);
    });
    setValues((prev) => ({
        ...prev,
        geom: {
            type: "Polygon",
            coordinates: [result],
        },
    }));
    // return result;
};
