import { useState } from "react";
// router
import { useParams } from "react-router";
// redux
import { useSelector } from "react-redux";
// formik
import { useFormik } from "formik";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import {
    Stack,
    TextField,
    FormControlLabel,
    Checkbox,
    Divider,
    Rating,
    Typography,
    Box,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
} from "@mui/material";
// components
import {
    PageTitle,
    SubmitButton,
    GenderSelect,
    CitySelect,
} from "../../../components";
import { ErrorsStack, UploadImagePreview } from "../../../sections";
// schema
import { addDriverSchema } from "../../../schemes/drivers/addDriverSchema";
// alerts
import { errorAlert, successAlert } from "../../../utils/alerts";

const AddDriver = () => {
    const userToken = useSelector((state) => state.userToken);
    const [file, setFile] = useState(null);
    const [resetPreview, setResetPreview] = useState(false);
    // ---------- submit_form_function ----------
    const onSubmit = (values) => {
        const formData = new FormData();
        for (let key in values) formData.append(key, values[key]);
        EnhancedAxios("multipart/form-data", userToken)
            .post(`/admin/users`, values)
            .then((res) => {
                resetForm();
                setSubmitting(false);
                successAlert(`تم اضافة السائق ${values.name}`);
                setResetPreview(true);
            })
            .catch((error) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    // ---------- form_configration ----------
    const {
        values,
        setValues,
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            img: null,
            roles: 4,
            name: "",
            phone: "",
            email: "",
            is_disabled: true,
            has_second_job: "",
            gender: null,
            nationality: "",
            car_type: "",
            car_year: null,
            car_model: "",
            birthdate: "",
            password: "",
            password_confirmation: "",
        },
        validationSchema: addDriverSchema,
        onSubmit,
    });
    // ---------- page_ui ----------
    return (
        <Stack width="100%" alignItems="center">
            <Stack gap="20px" width="100%" maxWidth="700px">
                <PageTitle title="اضافة السائق" />
                {/* ------------------------------------------- driver_form -------------------------------------------  */}
                <Stack spacing={1} component="form" onSubmit={handleSubmit}>
                    {/* <Typography variant="h6">البيانات الشخصية</Typography> */}
                    <UploadImagePreview
                        file={file}
                        setFile={setFile}
                        setValues={setValues}
                        resetPreview={resetPreview}
                    />
                    <TextField
                        type="text"
                        name="name"
                        label="اسم المستخدم"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <TextField
                        type="tel"
                        name="phone"
                        label="رقم الهاتف"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <TextField
                        type="email"
                        name="email"
                        label="البريد الالكترونى"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <GenderSelect values={values} setValues={setValues} />
                    <TextField
                        type="text"
                        name="nationality"
                        label="الجنسية"
                        value={values.nationality}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <TextField
                        type="date"
                        name="birthdate"
                        label="تاريخ الميلاد"
                        value={values.birthdate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <FormControl fullWidth>
                        <InputLabel>لديه وظيفة اخرى</InputLabel>
                        <Select
                            name="has_second_job"
                            value={values.has_second_job}
                            label="لديه وظيفة اخرى"
                            onChange={handleChange}
                        >
                            <MenuItem value={true}>نعم</MenuItem>
                            <MenuItem value={false}>لا</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        type="text"
                        name="car_type"
                        label="نوع السيارة"
                        value={values.car_type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <TextField
                        type="number"
                        name="car_year"
                        label="سنة انتاج السيارة"
                        value={values.car_year}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <TextField
                        type="text"
                        name="car_model"
                        label="موديل السيارة"
                        value={values.car_model}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <TextField
                        type="password"
                        name="password"
                        label="الرقم السري"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <TextField
                        type="password"
                        name="password_confirmation"
                        label=" تأكيد الرقم السري"
                        value={values.password_confirmation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={!values.is_disabled}
                                onChange={(e) =>
                                    setValues((prev) => ({
                                        ...prev,
                                        is_disabled: !e.target.checked,
                                    }))
                                }
                                name="is_disabled"
                            />
                        }
                        label="تفعيل"
                    />
                    <SubmitButton text="اضافة" disabled={isSubmitting} />
                    <ErrorsStack errors={errors} touched={touched} />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default AddDriver;
