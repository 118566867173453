import { useState } from "react";
// mui
import {
    Stack,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
} from "@mui/material";

const GenderSelect = ({ values, setValues }) => {
    const [gender, setGender] = useState(values.gender ?? "male");
    const handleChange = (e) => {
        setGender(e.target.value);
        setValues((prev) => ({ ...prev, gender: e.target.value }));
    };
    return (
        <FormControl fullWidth>
            <InputLabel>النوع</InputLabel>
            <Select value={gender} label="النوع" onChange={handleChange}>
                {genders.map((gender, index) => (
                    <MenuItem key={index} value={gender.value}>
                        {gender.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default GenderSelect;

const genders = [
    {
        label: "ذكر",
        value: "male",
    },
    {
        label: "أنثى",
        value: "female",
    },
];
