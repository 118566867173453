import { Typography, Avatar, Stack } from "@mui/material";
import logoImg from "../../assets/logo1.png";
import packageJson from "../../../package.json";

const NavbarLogo = () => {
    return (
        <Stack gap="5px">
            <Avatar
                src={logoImg}
                sx={{
                    width: "150px",
                    height: "150px",
                    backgroundColor: "white",
                }}
            />
            <Typography
                variant="body2"
                sx={{ color: "#fff", textAlign: "center" }}
            >
                V {packageJson.version}
            </Typography>
        </Stack>
    );
};

export default NavbarLogo;
