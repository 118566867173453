import React, { useState } from "react";
// redux
import { useSelector } from "react-redux";
// formik
import { useFormik } from "formik";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import { Stack, TextField, FormControlLabel, Switch } from "@mui/material";
// components
import { PageTitle, SubmitButton } from "../../../components";
import { ErrorsStack, UploadImagePreview } from "../../../sections";
// form validation schema
import { addCategorySchema } from "../../../schemes/categories/addCategorySchema";
// alerts
import { errorAlert, successAlert } from "../../../utils/alerts";

const AddSosCategory = () => {
    const userToken = useSelector((state) => state.userToken);
    const [file, setFile] = useState(null);
    const [resetPreview, setResetPreview] = useState(false);
    // ---------- submit_form_function ----------
    const onSubmit = (values) => {
        const formData = new FormData();
        for (let i in values) {
            formData.append(i, values[i]);
        }
        setSubmitting(false);
        EnhancedAxios("multipart/form-data", userToken)
            .post(`/admin/categories`, formData)
            .then((res) => {
                successAlert("تم اضافة الفئة بنجاح");
                setResetPreview(true);
                resetForm();
            })
            .catch((error) => errorAlert(error))
            .finally(() => setSubmitting(false));
    };
    // ---------- form_configration ----------
    const {
        values,
        setValues,
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            img: null,
            parent: JSON.stringify({
                id: `${process.env.React_APP_PARENT_ID_SOS_CATEGORIES}`,
            }),
            name: "",
            is_disabled: true,
        },
        validationSchema: addCategorySchema,
        onSubmit,
    });
    return (
        <Stack gap="20px" alignItems="center">
            <PageTitle title="اضافة فئة طلبات المساعدة" />
            <Stack
                spacing={1}
                component="form"
                width="100%"
                maxWidth="700px"
                onSubmit={handleSubmit}
            >
                {/* <UploadImagePreview
                    file={file}
                    setFile={setFile}
                    setValues={setValues}
                    resetPreview={resetPreview}
                /> */}
                <TextField
                    name="name"
                    label="اسم الفئة"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={!values.is_disabled}
                            onChange={(e) =>
                                setValues((prev) => ({
                                    ...prev,
                                    is_disabled: !e.target.checked,
                                }))
                            }
                            name="is_disabled"
                        />
                    }
                    label="تفعيل"
                />
                <SubmitButton text="اضافة" disabled={isSubmitting} />
                <ErrorsStack errors={errors} touched={touched} />
            </Stack>
        </Stack>
    );
};

export default AddSosCategory;
