import axios from "axios";
// jwt decoder library
import jwt_decode from "jwt-decode";
import { warningAlert } from "../utils/alerts";

const EnhancedAxios = (contentType, token, responseType = "json") => {
    if (isTokenExpired()) {
        window.localStorage.removeItem("userToken");
        warningAlert("انتهت فترة سماحية الدخول, برجاء تسجيلالدخول مرة اخرى.");
        window.location.href = "/login";
        return 0;
    } else {
        return axios.create({
            baseURL: process.env.REACT_APP_BASE_API_URL,
            withCredentials: true,
            responseType: responseType,
            headers: {
                "Content-Type": contentType ?? "application/json",
                Authorization: token ? `Bearer ${token}` : undefined,
            },
        });
    }
};
function isTokenExpired() {
    if (window.localStorage.getItem("userToken")) {
        const token = window.localStorage.getItem("userToken");
        const decodedToken = jwt_decode(token);
        return decodedToken.exp * 1000 <= Date.now();
    }
    return false;
}

export default EnhancedAxios;
