import React from "react";
// components
import { PageTitle } from "../../components";
import { LoginForm } from "../../sections";
// mui
import { Stack } from "@mui/material";
const Login = () => {
  return (
    <Stack
      width="100%"
      maxWidth="800px"
      spacing={8}
      alignItems="center"
      m="auto"
      py={8}
    >
      <PageTitle title="تسجيل الدخول" />
      <LoginForm />
    </Stack>
  );
};

export default Login;
