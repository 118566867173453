import { useState, useEffect } from "react";
// router
import { useParams } from "react-router";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import {
    Stack,
    TextField,
    Divider,
    Rating,
    Typography,
    Box,
    Button,
} from "@mui/material";
// components
import {
    PageTitle,
    UserLocation,
    RingsLoader,
    SelectRoles,
} from "../../../components";
import { ErrorsStack, UserChargeCash } from "../../../sections";
// alerts
import { errorAlert } from "../../../utils/alerts";
import { translateToArabic } from "../../../utils/translate/englishToArabic";

const EditUser = () => {
    const { userId } = useParams(); // get id of the user from the url
    const userToken = useSelector((state) => state.userToken);
    const [loading, setLoading] = useState(true);
    const [openChargeCashForm, setOpenChargeCashForm] = useState(false);
    const [user, setUser] = useState({});
    const [newBalanceValue, setNewBalanceValue] = useState(null);
    const [newUserWalletData, setNewUserWalletData] = useState({
        newBalanceValue: null,
        newTotalTransactionCount: null,
        newOverallBalance: null,
    });
    // ---------- submit_form_function ----------
    const onSubmit = (values) => {
        console.log(values);
    };
    // get user Data
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(`/admin/users/${userId}`)
            .then((res) => {
                setUser(res.data);
                setLoading(false);
                setNewUserWalletData({
                    newBalanceValue: res.data.wallet.balance,
                    newTotalTransactionCount: res.data.wallet.trx_count_deposit,
                    newOverallBalance: res.data.wallet.trx_total_deposit,
                });
            });
    }, []);
    // ---------- page_ui ----------
    if (loading) return <RingsLoader />;
    return (
        <Stack width="100%" alignItems="center">
            <Stack gap="20px" width="100%" maxWidth="700px">
                <PageTitle title="تعديل المستخدم" />
                <Stack spacing={1}>
                    <SelectRoles defaultValue={user?.roles}/>
                </Stack>
                {/* ------------------------------------------- user_form -------------------------------------------  */}
                <Stack spacing={1}>
                    <TextField
                        type="text"
                        name="name"
                        label="اسم المستخدم"
                        value={user.name ?? "لا يوجد"}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        type="tel"
                        name="phone"
                        label="رقم الهاتف"
                        value={user.phone ?? "لا يوجد"}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        type="email"
                        name="email"
                        label="البريد الالكترونى"
                        value={user.email ?? "لا يوجد"}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        type="text"
                        name="gender"
                        label="النوع"
                        value={
                            user.gender
                                ? translateToArabic(user.gender)
                                : "لا يوجد"
                        }
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Stack>
                <Divider flexItem />
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap="20px"
                    flexWrap="wrap"
                >
                    {/* ------------------------------------------- user_info -------------------------------------------  */}
                    <Stack gap="20px">
                        <Stack
                            alignItems="center"
                            gap="10px"
                            flex="1"
                            width="220px"
                        >
                            <Typography variant="subtitle2">التقييم</Typography>
                            <Rating
                                name="read-only"
                                value={user.avg_rate_as_customer}
                                precision={0.1}
                                readOnly
                            />
                        </Stack>
                        <Divider flexItem />
                        <Stack
                            alignItems="center"
                            gap="10px"
                            flex="1"
                            minWidth="200px"
                        >
                            <Typography variant="subtitle2">
                                الرصيد الحالى
                            </Typography>
                            <Typography variant="h6">
                                {newUserWalletData.newBalanceValue ||
                                    user.wallet.balance}
                            </Typography>
                        </Stack>
                        <Divider flexItem />
                        <Stack
                            alignItems="center"
                            gap="10px"
                            flex="1"
                            minWidth="200px"
                        >
                            <Typography variant="subtitle2">
                                عدد مرات التعبئة
                            </Typography>
                            <Typography variant="h6">
                                {newUserWalletData.newTotalTransactionCount ||
                                    user.wallet.trx_count_deposit}
                            </Typography>
                        </Stack>
                        <Divider flexItem />
                        <Stack
                            alignItems="center"
                            gap="10px"
                            flex="1"
                            minWidth="200px"
                        >
                            <Typography variant="subtitle2">
                                اجمالى الرصيد الذى تم تعبئته
                            </Typography>
                            <Typography variant="h6">
                                {newUserWalletData.newOverallBalance ||
                                    user.wallet.trx_total_deposit}
                            </Typography>
                        </Stack>
                    </Stack>
                    {/* ------------------------------------------- user_location -------------------------------------------  */}
                    <Stack
                        flex="1"
                        sx={{ minWidth: { xs: "270px", sm: "350px" } }}
                    >
                        <UserLocation
                            points={{
                                lat: user.location.coordinates[1],
                                lng: user.location.coordinates[0],
                            }}
                        />
                    </Stack>
                </Box>
                <Divider flexItem />
                <Stack>
                    <Box>
                        <Button
                            variant="outlined"
                            onClick={() => setOpenChargeCashForm(true)}
                        >
                            تعديل الرصيد
                        </Button>
                    </Box>
                    <UserChargeCash
                        openChargeCashForm={openChargeCashForm}
                        setOpenChargeCashForm={setOpenChargeCashForm}
                        currentBalance={user.wallet.balance}
                        setNewUserWalletData={setNewUserWalletData}
                        newUserWalletData={newUserWalletData}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default EditUser;
