import { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// hookds
import { usePageParams } from "../../../hooks/usePageParams";
//axios
import EnhancedAxios from "../../../api/EnhancedAxios";
// mui
import { Stack, Box, Typography } from "@mui/material";
// components
import {
    PageTitle,
    RingsLoader,
    SearchBox,
    SortBox,
    IsRegectedFilter,
} from "../../../components";
import { TableGrid } from "../../../sections";
import { driverJoinRequestsCols } from "../../../utils/gridCols/driverJoinRequestsCols";
// alerts
import { errorAlert } from "../../../utils/alerts";
const DriversJoinRequests = () => {
    const userToken = useSelector((state) => state.userToken);
    const [loading, setLoading] = useState(true);
    const [drivers, setDrivers] = useState([]);
    // const [currentPage, setCurrentPage] = useState(1);
    const { page, setPage } = usePageParams();
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [totalItems, setTotalItems] = useState(20);
    const [searchValue, setSearchValue] = useState("");
    const [sortValue, setSortValue] = useState("-created_at");
    const [filterValues, setFilterValues] = useState({
        status: "is_disabled:eq:false",
        is_rejected: "is_rejected:eq:false",
    });
    // get all drivers
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(
                `admin/driver-forms?filters=${filterValues.status},is_complete:false,${filterValues.is_rejected}&page=${page}&paginate=${itemsPerPage}&q=${searchValue}&sorts=${sortValue}`
            )
            .then((res) => {
                setDrivers(res.data.data);
                setTotalItems(res.data.meta.total);
                setLoading(false);
            })
            .catch((error) => errorAlert(error));
    }, [page, filterValues, itemsPerPage, sortValue, searchValue]);
    return (
        <Stack gap="20px">
            <PageTitle title="إدارة طلبات  انضمام السائقين" />
            <Stack gap="20px">
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap="20px"
                    flexWrap="wrap"
                >
                    <SearchBox
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                    <SortBox
                        sortValue={sortValue}
                        setSortValue={setSortValue}
                    />
                    <IsRegectedFilter
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                        setPage={setPage}
                    />
                </Box>
            </Stack>
            <Stack>
                {loading ? (
                    <RingsLoader />
                ) : drivers.length === 0 && searchValue === "" ? (
                    <Typography variant="body1" textAlign="center">
                        لا يوجد عناصر لعرضها.
                    </Typography>
                ) : drivers.length === 0 && searchValue !== "" ? (
                    <Typography variant="body1" textAlign="center">
                        {" "}
                        لا يوجد عناصر لعرضها خاصة نتيجة البحث.
                    </Typography>
                ) : (
                    <TableGrid
                        rows={drivers}
                        cols={driverJoinRequestsCols}
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        currentPage={page}
                        setCurrentPage={setPage}
                    />
                )}
            </Stack>
        </Stack>
    );
};

export default DriversJoinRequests;
