import React, { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../api/EnhancedAxios";
// form
import { useFormik } from "formik";
// mui
import { Stack, Box, TextField, Typography } from "@mui/material";
// components
import { RingsLoader, SubmitButton } from "../../components";
// alerts
import { errorAlert, successAlert } from "../../utils/alerts";

const AboutApp = () => {
    const userToken = useSelector((state) => state.userToken);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [about, setAbout] = useState({
        id: 0,
        key: "about",
        value: "",
    });
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/admin/settings?sorts=-id&page=1&paginate=9999&q=about`)
            .then((res) => {
                setAbout((prev) => ({
                    ...prev,
                    id: res.data.data[0].id,
                    key: res.data.data[0].key,
                    value: res.data.data[0].value,
                }));
            });
    }, []);
    const handleChange = (e) => {
        setAbout((prev) => ({ ...prev, value: e.target.value }));
    };
    const submitEdit = (e) => {
        e.preventDefault();
        if (about.value.length > 255) {
            errorAlert("حول التطبيق طويل جدا");
        } else {
            setButtonDisabled(true);
            const formData = new FormData();
            formData.append("value", about.value);
            EnhancedAxios("multipart/form-data", userToken)
                .put(`/admin/settings/${about.id}`, formData)
                .then((res) => {
                    successAlert("تم تعديل حول التطبيق");
                    setButtonDisabled(false);
                })
                .catch((error) => {
                    errorAlert(error);
                    setButtonDisabled(false);
                });
        }
    };
    return (
        <Stack gap="20px" component="form" onSubmit={submitEdit}>
            <Typography variant="h5">حول النظام</Typography>
            <TextField
                name="about"
                value={about.value}
                onChange={handleChange}
                multiline
                minRows={1}
                maxRows={7}
            />
            <SubmitButton text="تعديل" disabled={buttonDisabled} />
        </Stack>
    );
};

export default AboutApp;
